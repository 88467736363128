$(function () {
    $('.dropdown').on('mouseenter mouseleave click tap', function (e) {
        $(this).toggleClass("open");
    });

    $(".mobile-menu-toggle").click(function () {
        $("#mobile-nav").toggleClass("active");
        $(this).toggleClass("open");
    });

    function checkRootMenu() {
        if ($(".root-menu").hasClass("active")) {
            $("#back").addClass("active");
        } else {
            $("#back").removeClass("active");
        }
    }
    checkRootMenu();

    function changeMenu(target) {
        var newMenu = $("#" + target);
        $("#back").attr("data-target", $(".menu.active").attr("id"));
        $(".menu.active").removeClass("active");
        $(newMenu).addClass("active");
        checkRootMenu();
    }

    if ($(".menu.active").find("li").length) {
    } else {
        changeMenu($("#back").data("target"));
    }

    $(".next").click(function () {
        changeMenu($(this).data("target"));
    });
    $("#back").click(function () {
        changeMenu($(".menu.active").data("parent"));
    });
	
    //Adjusts header image if needed
    adjustHeaderImage();
    adjustFooter();
    $(window).on('resize', function () {
        adjustHeaderImage();
        adjustFooter();
    });
    $(window).on("orientationchange", function () {
        adjustHeaderImage();
        adjustFooter();
    });
});

function adjustHeaderImage() {
    if ($(".header-background").length && $(".page-header").length) {
        $(".header-background").css("height", "0");
        var headerOffset = $(".text-page h1").offset().top + $(".text-page h1").height();
        var headerHeight = $(".header-background").height();
        var bottomMargin = 20;

        if (headerOffset + bottomMargin > headerHeight) {
            $(".header-background").height(headerOffset + bottomMargin);
        }
    }
};

function adjustFooter() {
    var footerHeight = $("footer").outerHeight();
    $("#wrap").css("margin-bottom", -footerHeight);
    $(".push").css("height", footerHeight);
};